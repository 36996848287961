@import url('https://fonts.googleapis.com/css?family=Montserrat:400,600&display=swap');
body,
.main-font {
    font-family: 'Montserrat';
    font-weight: 600;
}

.second-font {
    font-family: 'Montserrat';
    font-weight: 400;
}

html,
body {
    font-size: 12px;
}