.body-height {
  min-height: calc(100vh - 120px);
}

.cursor-pointer {
  cursor: pointer;
}

.text-align-r {
  text-align: right;
}

.text-align-l {
  text-align: left;
}

.margin-auto {
  margin: 0 auto;
}

.bold {
  font-weight: bold;
}

.no-padding {
  padding: 0 !important;
  margin: 0 !important;
}