.DropdownMenu-Language {
    top: 100%!important;
    padding: 0!important;
    background: white!important;
}

@media (max-width: 992px) {
    .DropdownMenu-Language.show {
        top: 0px!important;
        left: auto!important;
        right: auto!important;
    }
}

/* .DropdownMenu-Language button{

} */