.dropdown-menu {
    left: auto!important;
    right: 0!important;
}

.dropdown-toggle {
    font-weight: 600;
}

.navbar {
    position: sticky !important;
    z-index: 1020 !important;
    top: 0 !important;
}

@media (min-width: 992px) {
    .show.userClass a {
        color: white!important;
    }
    .navbar-expand-lg {
        border-bottom: 1px solid;
    }
    .navbar-expand-lg .navbar-nav .userClass {
        padding-right: .5rem;
        padding-left: .5rem;
        padding-top: .7rem;
        padding-bottom: .7rem;
        border-radius: 50%;
        letter-spacing: .1rem;
        border: 2px solid;
        width: 65px;
        height: 65px;
        text-align: center;
        font-size: 16px;
        line-height: 2;
        -webkit-transition: all ease 0.8s;
        -moz-transition: all ease 0.8s;
        transition: all ease 0.8s;
    }
    .navbar-expand-lg .navbar-nav .userClass:hover a {
        color: #fff;
    }
    .color-haier a:hover,
    .color-haier:hover a {
        color: white!important;
    }
    .line-height-lg-3 {
        line-height: 3;
    }
    .dropdown-menu.show {
        animation-duration: 0.3s;
        -webkit-animation-duration: 0.3s;
        animation-fill-mode: both;
        -webkit-animation-fill-mode: both;
        -webkit-animation-name: slideIn;
        animation-name: slideIn;
    }
}

@media (min-width: 991px) {
    .navbar-collapse-custom.show {
        display: none;
    }
}

@media (max-width: 991px) {
    .dropdown-toggle.nav-link {
        text-align: center;
        color: white!important;
    }
    .navbar-collapse-custom {
        display: none;
    }
    .navbar-collapse-custom.show {
        padding-top: 2rem;
        width: 100%;
        display: inline-block;
        height: 100vh;
    }
    .navbar-collapse-custom.show a {
        color: rgb(90, 90, 90)!important;
    }
}

@keyframes slideIn {
    0% {
        transform: translateY(1rem);
        opacity: 0;
    }
    100% {
        transform: translateY(0rem);
        opacity: 1;
    }
    0% {
        transform: translateY(1rem);
        opacity: 0;
    }
}

@-webkit-keyframes slideIn {
    0% {
        -webkit-transform: transform;
        -webkit-opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
        -webkit-opacity: 1;
    }
    0% {
        -webkit-transform: translateY(1rem);
        -webkit-opacity: 0;
    }
}

.slideIn {
    -webkit-animation-name: slideIn;
    animation-name: slideIn;
}

.notification-order {
    font-size: 10px;
    display: block;
    text-align: center;
    width: 20px;
    height: 20px;
    position: absolute;
    border-radius: 50%;
    padding-top: 4px;
    line-height: 12px;
    color: #fff;

    left: 12px;
    top: -5px;
    right: 0;
}